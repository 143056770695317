// Titles
.big-title {
  margin-bottom: 60px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  .big-title-top {
    display: flex;
    align-items: center;
  }

  svg {
    width: 35.71px;

    @media (max-width: 768px) {
      width: 21px;
    }
  }

  h3 {
    margin-left: 40px;

    i, em {
      font-weight: 400;
    }

    @media (max-width: 768px) {
      margin-left: 15px;
    }
  }

  p {
    margin-top: 45px;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }
}

h1, h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 125%;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 137%;
  }

  i, em {
    font-weight: 400;
  }
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 121%;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 123%;
  }
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 121%;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

// Texts
.regular-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 137%;
  letter-spacing: 0.02em;
  margin: 0;

  .is-center {
    text-align: center;
  }
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.02em;
  margin: 0;
}
