.gradient-mobile-menu {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    width: 100%;
    height: 812px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('/img/site/background-gradient-mobile-4.webp');
    
    @media (max-width: 768px) {
        display: block;
    }
}

.gradient1 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1128px;
    height: 1119px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('/img/site/background-gradient-1.webp');

    @media (max-width: 768px) {
        bottom: -50%;
        width: 100%;
        height: 743px;
        background-size: cover;
        background-image: url('/img/site/background-gradient-mobile-1.webp');
    }
}

.gradient3 {
    position: absolute;
    top: -125%;
    right: 0;
    width: 1440px;
    height: 1312px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('/img/site/background-gradient-2.webp');

    @media (max-width: 768px) {
        top: -100%;
        width: 100%;
        height: 511px;
        background-size: cover;
        background-image: url('/img/site/background-gradient-mobile-2.webp');
    }
}

.gradient5 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1440px;
    height: 1488px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('/img/site/background-gradient-3.webp');

    @media (max-width: 768px) {
        width: 100%;
        height: 637px;
        background-size: cover;
        background-image: url('/img/site/background-gradient-mobile-3.webp');
    }
}