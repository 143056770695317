.home-hero {
	  position: relative;
    z-index: 4;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    z-index: 2;
    pointer-events: none;
    overflow: hidden;

    @media(max-width: 600px) {
      min-height: auto;
    }

    .home-hero-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 56.25vw;
      min-height: 100vh;
      min-width: 177.77vh;
      transform: translate(-50%, -50%);

      @media(max-width: 600px) {
        min-width: calc(100vh * 0.55555556);
        min-height: calc(100vw * 1.8);
      }
    }
}

.home-title-cta {
  position: relative;

  .home-title-cta-button {
    position: relative;
    display: inline-block;
    margin-top: 40px;
    font-family: $fontMontserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 121%;
    text-transform: uppercase;
    color: $colorAccent1;

    &:after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 40px;
      height: 2px;
      background: $colorAccent1;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}

.home-three-columns {
  position: relative;

  .home-three-columns-items {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 40px;
    border-top: 1px solid $light;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-top: 0;
      border-top: none;
    }

    .three-columns-items-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc((100% / 3) - (80px / 3));
      margin-right: 40px;

      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding-top: 20px;
        margin-bottom: 50px;
        margin-right: 0;
        border-top: 1px solid $light;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .card-title,
    .card-text {
      color: $light;
    }

    .card-divider {
      width: 100%;
      height: 1px;
      margin: 40px 0 30px;
      background: $light;

      @media (max-width: 768px) {
        margin: 20px 0;
      }
    }

    .card-image {
      display: block;
      width: 100%;
      margin-top: 30px;
    }
  }
}

.home-careers {
  position: relative;

  .home-careers-items {
    display: flex;
    flex-direction: column;

    .careers-accordion {
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid $light;

      &:first-of-type {
        border-top: 1px solid $light;
      }

      @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &.is-active {
        .accordion-header svg {
          transform: rotate(180deg);
        }

        .accordion-content {
          max-height: 500vh;
          padding: 60px 0 20px;
        }
      }

      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        svg {
          width: 30px;

          @media (max-width: 768px) {
            width: 20px;
          }
        }
      }

      .accordion-content {
        position: relative;
        overflow: hidden;
        max-height: 0;
        padding: 0;
        transition: all 0.3s ease-out;

        .accordion-content-details {
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: stretch;
          margin-bottom: 40px;
          padding-left: 13.2%;
          padding-right: 13.2%;

          @media (max-width: 768px) {
            flex-direction: column;
          }
        }

        .details-column {
          width: calc(50% - (110px / 2));

          &:first-of-type {
            margin-right: 110px;
          }

          @media (max-width: 768px) {
            width: 100%;

            &:first-of-type {
              margin-right: 0;
              margin-bottom: 40px;
            }
          }

          p {
            margin-bottom: 40px;
            font-weight: 500;
          }

          ul {
            position: relative;
            height: 100%;
            padding-left: 20px;
            list-style: none;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -40px;
              width: 1px;
              height: 100%;
              background: $colorAccent1;
            }

            li {
              margin-bottom: 30px;

              &::before {
                content: "\2022";
                color: $colorAccent1;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
              }
            }
          }
        }

        .button {
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
}

.home-contact {
  position: relative;

  .contact-form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .contact-form-column {
    width: calc(50% - 20px);

    &:first-of-type {
      margin-right: 40px;
    }

    @media (max-width: 768px) {
      width: 100%;

      &:first-of-type {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .form-group {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .button-wrapper {
      margin-top: 30px;

      @media (max-width: 768px) {
        .button {
          width: 100%;

          svg {
            width: 100%;
          }
        }
      }
    }
  }

  .form-message {
    margin-bottom: 40px;
  }
}
