$line-size: 1px;
$delay1: .5s;
$delay2: .9s;
$distance: -1px;

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 180px;
  height: 60px;
  border: 2px solid $colorAccent1;
  text-decoration: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  transition: 1s ease-in-out;

  .line {
    position: absolute;
    opacity: 1;
    background: $light;
    transition: none;
    transform: scale(0);

    &:nth-child(1) {
      transform-origin: 100% 100%;
      height: $line-size;
      width: 20%;
      bottom: 0;
    }

    &:nth-child(3) {
      transform-origin: 0 100%;
      height: $line-size;
      width: 100%;
      top: $distance;
      left: $distance;
    }

    &:nth-child(2) {
      transform-origin: 100% 100%;
      height: 100%;
      width: $line-size;
      bottom: 0;
      left: $distance;
    }

    &:nth-child(4) {
      transform-origin: 0 100%;
      height: $line-size;
      width: 20%;
      top: $distance;
      right: 0;
    }

    &:nth-child(5) {
      transform-origin: 100% 0;
      height: 100%;
      width: $line-size;
      bottom: 0;
      right: $distance;
    }

    &:nth-child(6) {
      transform-origin: 100% 0;
      height: $line-size;
      width: 100%;
      bottom: 0;
      right: $distance;
    }
  }

  &:hover {
    border-color: $colorAccent2;

    & > .line {
      transform: scale(1);
      opacity: 0;
      transition: all 1s;
    }

    & > .line:nth-child(2),
    & > .line:nth-child(5) {
      transition-delay: $delay1;
    }

    & > .line:nth-child(3),
    & > .line:nth-child(6) {
      transition-delay: $delay2;
    }
  }

  span {
    color: $light;
    font-size: 14px;
    font-weight: bold;
    line-height: 121%;
    text-transform: uppercase;
  }
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
}