// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: $fontMontserrat;
  @extend .regular-text;
  color: $light;
  background-color: $dark;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontMontserrat;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #C2C7D0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #C2C7D0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #C2C7D0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #C2C7D0;
}

.hide-for-screen {
  font-size: 0;
}

/* -- For screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 1px solid #5db7a5; /* Adjust to suit your tastes */
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

html.user-is-tabbing *:focus {
    outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}

::-moz-selection { background: $colorAccent1; color: #fff; }
::selection { background: $colorAccent1; color: #fff; }

[v-cloak] {
  display: none;
}

.site-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main {
    flex: 1;

    section:last-of-type {
      padding-bottom: 100px;
    }
  }
}

.has-noise {
    background-position: center;
    background-repeat: repeat-y;
    background-size: contain;
    background-image: url('/img/site/background-noise.webp');

}

.container {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 4%;
  padding-right: 4%;

  &.gap-top {
    padding-top: 150px;

    @media (max-width: 768px) {
      padding-top: 40px;
    }
  }

  &.gap-bottom {
    padding-bottom: 150px;

    @media (max-width: 768px) {
      padding-bottom: 60px;
    }
  }
}

input#yield-ghost { display: none; }

.custom-git-branch {
  position: fixed;
  display: flex;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: $colorAccent1;
  color: #fff;
  z-index: 999999;
  pointer-events: none;

  & > svg {
    width: auto;
    margin-right: 5px;
  }
}
