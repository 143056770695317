.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    background: $dark;

    @media (max-width: 830px) {
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .footer-left {
        display: flex;
        align-items: center;

        @media (max-width: 830px) {
            flex-direction: column;
        }
    }
    
    .footer-logo {
        position: relative;
        width: 113px;
        margin-right: 50px;

        svg {
            width :100%;
        }

        @media (max-width: 830px) {
            margin-bottom: 30px;
        }
    }

    .footer-menu {
        display: flex;

        @media (max-width: 830px) {
            margin-bottom: 50px;
        }
    }

    .footer-menu-link {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 125%;
        text-transform: uppercase;
        color: $colorAccent1;

        &:last-of-type {
            margin-right:  0;
        }

        &.for-lang {
            text-decoration: underline;
            text-transform: capitalize;
        }
    }
}
