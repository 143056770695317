// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
// @import url(...);

// Fonts
$fontMontserrat: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #8DCDF2;
$colorAccent2: #166CED;

// Texts
$dark: #1F1F1F;
$light: #FFFFFF;

$colorSuccess: #00966D;
$colorError: #880000;
