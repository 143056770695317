button, input, optgroup, select, textarea {
  font-family: $fontMontserrat;
}

.form-input {
  width: 100%;
  padding: 14px 20px;
  border: none;
  @extend .regular-text;
  color: $light;
  font-weight: 500;
  background: rgba(#000, .4);
  outline: 0;
  resize: none;
}

::placeholder {
  color: rgba($light, .7);
  font-weight: 400;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgba($light, .7);
 font-weight: 400;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: rgba($light, .7);
 font-weight: 400;
}

.form-message {
  display: flex;
  align-items: center;
  font-weight: 600;

  &.error {
    color: $colorError;
  }

  svg {
    margin-right: 10px;
  }
}

.pristine-error {
  display: flex;
  align-items: center;
  margin-top: 5px;
  @extend .subtitle;
  font-weight: 600;
  color: $colorError;

  svg {
    margin-right: 10px;
  }
}

.form-stretch {
  flex: 1;
}

.custom-select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  cursor: pointer;
}

.custom-select {
  position: absolute; 
  inset: 0;
  overflow: hidden;
  padding: 14px 45px 14px 20px;
  @extend .regular-text;
  color: $light;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: rgba(#000, .4);
  transition: all 0.2s ease-in;

  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 10px;
    top: 20px;
    right: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxOCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjE2NjcyIDEwTDAuMzMzNDM0IDEuMTY2NzFMMS41MDAxNSAtMS44NjY0OWUtMDZMOS4xNjY3MiA3LjY2NjU3TDE2LjgzMzMgLTUuMjYwMjZlLTA3TDE4IDEuMTY2NzJMOS4xNjY3MiAxMFoiIGZpbGw9IiM4RENERjIiLz4KPC9zdmc+Cg==");
  }

  &:active,
  &.is-active {
    color: rgba($light, .4);
    background-color: #000;

    &:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxOCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjE2NjcyIDcuNzIyM2UtMDdMMC4zMzM0MzQgOC44MzMyOUwxLjUwMDE1IDEwTDkuMTY2NzIgMi4zMzM0M0wxNi44MzMzIDEwTDE4IDguODMzMjhMOS4xNjY3MiA3LjcyMjNlLTA3WiIgZmlsbD0iIzhEQ0RGMiIvPgo8L3N2Zz4K");
    }
  }
}

.custom-select-options {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 90;
  display: none; 
  margin: 0;
  padding: 0;
  list-style: none;

  background-color: #000;

  li {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 14px 25px 14px 0;
    text-indent: 20px;
    @extend .regular-text;
    font-weight: 500;
    color: $light;
    text-overflow: ellipsis;
    white-space: nowrap;
    // border-top: 1px solid rgba($light, .2);
    transition: all 0.15s ease-in;

    &:hover, 
    &.is-selected {
      color: #000;
      background: $colorAccent1;
    }

    &[rel="hide"] {
      display: none;
    }
  }
}