.header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
    background: transparent;

    @media (max-width: 768px) {
        padding-top: 30px;
    }
    
    .header-logo {
        position: relative;
        z-index: 20;
        width: 190px;

        svg {
            width :100%;
        }

        @media (max-width: 768px) {
            width: 113px;
        }
    }

    .header-menu {
        display: flex;

        &.has-noise {
            background: transparent;
        }

        @media (max-width: 768px) {
            position: fixed;
            inset: 0;
            z-index: 18;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 0;
            height: 100vh;
            padding: 0;
            background: $dark;
            transition: .6s all .3s ease-in-out;

            &.is-active {
                width: 100%;
                padding: 4%;
                transition: all .3s ease-in-out;

                .header-menu-link {
                    width: 100%;
                    opacity: 1;
                    transition: .6s all .3s ease-in-out;
                }
            }

            &.has-noise {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('/img/site/background-mobile-noise.png');
                background-color: $dark;
            }
        }
    }

    .header-menu-link {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 121%;
        text-transform: uppercase;
        color: $colorAccent1;


        &:last-of-type {
            margin-right:  0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 40px;
            height: 2px;
            background: $colorAccent1;
            transition: all .3s ease-in-out;
        }

        &:hover,
        &.is-active {
            &:after {
                width: 100%;
            }
        }

        @media (max-width: 768px) {
            position: relative;
            overflow: hidden;
            opacity: 0;
            width: 0;
            padding-top: 30px;
            padding-bottom: 30px;
            font-family: $fontMontserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 106%;
            border-top: 1px solid $light;
            transition: all .3s ease-in-out;

            &:last-of-type {
                border-bottom: 1px solid $light;
            }

            &:after {
                content: none;
            }

            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                width: 40px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 41 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30.9795 0.161677C30.7812 0.377246 30.7812 0.7275 30.9795 0.943078L39.264 9.948L0.508487 9.948C0.227773 9.948 -4.72276e-07 10.1956 -4.58938e-07 10.5007C-4.45601e-07 10.8058 0.227773 11.0534 0.508487 11.0534L39.264 11.0534L30.9808 20.0569C30.7825 20.2725 30.7825 20.6227 30.9808 20.8383C31.1791 21.0539 31.5013 21.0539 31.6997 20.8383L40.8505 10.8917C40.8973 10.8408 40.935 10.779 40.9614 10.7115C40.9867 10.6441 41 10.5721 41 10.5003C41 10.4285 40.9867 10.3568 40.9614 10.2892C40.936 10.2208 40.8984 10.1599 40.8505 10.109L31.6997 0.162367C31.5003 -0.0541243 31.1789 -0.0538897 30.9795 0.161677Z' fill='white'/%3E%3C/svg%3E%0A");
                transform: translateY(-50%);
            }
        }
    }

    .header-trigger-mobile-menu {
        position: relative;
        z-index: 20;
        display: none;
        width: 50px;
        height: 50px;
        cursor: pointer;

        @media (max-width: 768px) {
            display: block;
        }
    
        .trigger-mobile-menu-checkbox {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: 2;
            opacity: 0;
            -webkit-touch-callout: none;
        }

        .trigger-mobile-menu-icon {
            position: absolute;
            inset: 0;
            width: 35px;
            height: 18px;
            margin: auto;

            span {
                position: absolute;
                display: block;
                height: 3px;
                background-color: $colorAccent1;
                transition: all 0.2s cubic-bezier(.1, .82, .76, .965);
        
                &:first-of-type {
                    top: 0;
                    right: 0;
                    width: 40px;
                }

                &:last-of-type {
                    bottom: 0;
                    right: 0;
                    width: 25px;
                }
            }
        }

        &.is-active,
        .trigger-mobile-menu-checkbox:checked + .trigger-mobile-menu-icon {
            span {
                &:first-of-type {
                    top: 7.95px;
                    width: 35px;
                    transform: rotate(45deg);
                }

                &:last-of-type {
                    bottom: 7.95px;
                    width: 35px;
                    transform: rotate(-45deg);
                }
            }
        }
    
        &.is-active:hover .trigger-mobile-menu-icon span:first-of-type,
        &.is-active:hover .trigger-mobile-menu-icon span:last-of-type,
        &:hover .trigger-mobile-menu-checkbox:checked + .trigger-mobile-menu-icon span:first-of-type,
        &:hover .trigger-mobile-menu-checkbox:checked + .trigger-mobile-menu-icon span:last-of-type {
            width: 35px;
        }
    
        &:hover {
            // no need hover effect on mobile.
            @media (min-width: 1024px) {
                .trigger-mobile-menu-icon span {
                    width: 100%;
                }
            }
        }
    }
}
