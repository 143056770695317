.popup-application-form {
    position: fixed;
    inset: 0;
    z-index: 50;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(#000, .8);

    &.is-active {
        display: flex;
    }

    .application-form-wrapper {
        position: relative;
        padding: 120px 110px;

        @media (max-width: 768px) {
            overflow-y: auto;
            max-height: calc(100vh - 80px);
            padding: 70px 20px 40px;
        }

        &.has-noise {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('/img/site/background-noise-popup.webp');
            background-color: $dark;
        }

        .application-form-gradient {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('/img/site/background-gradient-popup.webp');
        
            @media (max-width: 768px) {
                background-image: url('/img/site/background-gradient-mobile-popup.webp');
            }
        }
    }

    .application-form-content {
        position: relative;
        z-index: 5;
    }

    .application-form {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .application-form-close {
        position: absolute;
        top: 40px;
        right: 40px;
        border: none;
        background: transparent;
        outline: 0;
        cursor: pointer;

        svg {
            width: 25px;
            height: 25px;
        }

        @media (max-width: 768px) {
            top: 20px;
            right: 20px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .full-height {
        min-height: 180px;
        height: 100%;
    }

    .application-form-column {
        display: flex;
        flex-direction: column;
        width: calc(50% - 20px);

        &:first-of-type {
            margin-right: 40px;
        }

        @media (max-width: 768px) {
            width: 100%;

            &:first-of-type {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }

        .form-group {
            margin-bottom: 15px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .button-wrapper {
        width: 100%;
        margin-top: 30px;

        @media (max-width: 768px) {
            .button {
                width: 100%;

                svg {
                    width: 100%;
                }
            }
        }
    }

    .form-files-heading {
        margin: 0 0 10px;
    }

    .form-files-container {
        position: relative;
        padding: 25px 30px;
        background-color:transparent;

        &.has-advanced-upload {
            outline: 3px dashed rgba(#000, .4);
    
            -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;

            .box__dragndrop {
                display: inline;
            }
        }

        .form-files-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .form-files-input {
            display: none;
        }

        .form-files-label {
            margin: 10px auto;
            font-size: 12px;
            line-height: 15px;
            text-align: center;

            label {
                color: $colorAccent1;
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .subtitle {
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.02em;
            color: rgba($light, .5);
        }

        &.is-dragover {
            outline-offset: -20px;
        }

        &.is-uploading .box__input,
        &.is-success .box__input,
        &.is-error .box__input {
            visibility: hidden;
        }

        &.is-uploading .box__uploading,
        &.is-success .box__success,
        &.is-error .box__error {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;

            -webkit-transform: translateY( -50% );
            transform: translateY( -50% );
        }

        .box__dragndrop,
        .box__icon {
            display: none;
        }

        .box__uploading,
        .box__success,
        .box__error {
            display: none;
        }
        
        .box__uploading {
            font-style: italic;
        }

        .box__success {
            -webkit-animation: appear-from-inside .25s ease-in-out;
            animation: appear-from-inside .25s ease-in-out;
        }

        @-webkit-keyframes appear-from-inside
        {
            from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
            75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
            to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
        }
        @keyframes appear-from-inside
        {
            from	{ transform: translateY( -50% ) scale( 0 ); }
            75%		{ transform: translateY( -50% ) scale( 1.1 ); }
            to		{ transform: translateY( -50% ) scale( 1 ); }
        }

        .box__restart
        {
            font-weight: 700;

            &:focus,
            &:hover {
                color: #39bfd3;
            }
        }

        .js .box__file {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;

            & + label {
                max-width: 80%;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
            }
        }

        .js .box__file + label:hover strong,
        .box__file:focus + label strong,
        .box__file.has-focus + label strong {
            color: #39bfd3;
        }

        .js .box__file:focus + label,
        .js .box__file.has-focus + label {
            outline: 1px dotted #000;
            outline: -webkit-focus-ring-color auto 5px;
        }

        .js .box__file + label * {
            /* pointer-events: none; */ /* in case of FastClick lib use */
        }

        .no-js .box__file + label {
            display: none;
        }

        .no-js .box__button {
            display: block;
        }

        .box__button {
            font-weight: 700;
            color: #e5edf1;
            background-color: #39bfd3;
            display: none;
            padding: 8px 16px;
            margin: 40px auto 0;

            &:hover,
            &:focus {
                background-color: #0f3c4b;
            }
        }
    }

    .form-files-list {
        margin: 15px 0;

        &.is-empty {
            margin: 0;
        }

        p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            color: $light;

            &:last-of-type {
                margin-bottom: 0;
            }

            span {
                flex: 1;
                margin: 0 15px;
            }

            button {
                border: none;
                background: transparent;
                outline: 0;
                cursor: pointer;
            }
        }
    }
}